<template lang="html">
    <div class="bread-crumb">
        <img src="@/assets/icon/location.png" alt="map"/>
        <el-breadcrumb>
            <el-breadcrumb-item v-for="(item, index) in breadCrumbList" :key="index">
                <span class="bread-crumb-item" @click="navigateTo(item)">{{ item.name }}</span>
<!--                <span class="bread-crumb-item"><a :href="item.path">{{ item.name }}</a></span>-->
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
    export default {
        name: "BreadCrumb",
        data() {
            return {
                breadCrumbList: this.$store.state.breadCrumbList
            };
        },
        watch: {
            "$route.path": function () {
                this.breadCrumbList = this.$store.state.breadCrumbList;
            }
        },
        methods: {
            navigateTo(item){
                if (this.$route.path == item.path || item.disabled) {
                    return;
                }
                this.$router.push(item.path);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .bread-crumb {
        margin: 12px 24px;
        line-height: 1;
        font-size: 12px;

        div {
            display: inline-block;
        }

        img {
            display: inline-block;
            margin-right: 10px;
            width: 16px;
            height: 16px;
        }
    }
    .bread-crumb-item:hover {
        cursor: pointer;
        color:#3A7FFF;
    }
</style>
